// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cognitoUserPoolId: 'ap-south-1_opQe4kyUJ',
  cognitoAppClientId: '3lo4lcti41avjbhig0dnqh5e6v',
  cognitoRegion: 'ap-south-1',
  mapsKey: 'AIzaSyB5_20yGuMNE63tlN389yBh1IUEY9u3hJM'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * 
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
