import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'home/:id',
    loadChildren: () => import('./details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'cars',
    loadChildren: () => import('./cars/cars.module').then(m => m.CarsPageModule)
  },
  {
    path: 'cars/:isAddCar',
    loadChildren: () => import('./cars/cars.module').then(m => m.CarsPageModule)
  },
  // {
  //   path: 'cars/:carId',
  //   loadChildren: () => import('./schedule/schedule.module').then(m => m.SchedulePageModule)
  // },
  {
    path: 'details/:id',
    loadChildren: () => import('./details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'car-locations',
    loadChildren: () => import('./car-locations/car-locations.module').then(m => m.CarLocationsPageModule)
  },
  // {
  //   path: 'schedule',
  //   loadChildren: () => import('./schedule/schedule.module').then(m => m.SchedulePageModule)
  // },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'course-summary/:courseId',
    loadChildren: () => import('./course-summary/course-summary.module').then(m => m.CourseSummaryPageModule)
  },
  {
    path: 'my-rides',
    loadChildren: () => import('./my-rides/my-rides.module').then(m => m.MyRidesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'booking-confirmation',
    loadChildren: () => import('./booking-confirmation/booking-confirmation.module').then(m => m.BookingConfirmationPageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsPageModule)
  },
  {
    path: 'qr-code/:carId/:carModel/:carNumber',
    loadChildren: () => import('./qr-code/qr-code.module').then(m => m.QrCodePageModule)
  },  {
    path: 'attendance',
    loadChildren: () => import('./attendance/attendance.module').then( m => m.AttendancePageModule)
  },
  {
    path: 'customer-locations',
    loadChildren: () => import('./customer-locations/customer-locations.module').then( m => m.CustomerLocationsPageModule)
  }







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
